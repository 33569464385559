import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import axiosConfig from "../../../axiosConfig";
import { useDispatch, useSelector } from "react-redux";

// Antd
import { Checkbox, Divider, Tooltip, Select } from "antd";

// Images & Icons
import left_arrow from "../../../assets/images/left-arrow.svg";
import right_arrow from "../../../assets/images/right-arrow.svg";
import Placeholder_image from "../../../assets/images/image_placeholder.gif";

// Components Used
import NoData from "../../general/NoData";
import { COLORS } from "../../../Constants";
import DataFetchIngLoader from "../../general/DataFetchIngLoader";
import { updateDataProcessed } from "../../redux/reducers/UserReducer";
import { formatDatev2, formatTime } from "../../../utils/HelperFunctions";

function CarReversalMain() {
  const dispatch = useDispatch();
  const { Option } = Select;

  const { userId } = useSelector((state) => state?.user?.userProfile);

  const [loading, setLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  const [carReversal, setCarReversal] = useState([]);
  const [checkedState, setCheckedState] = useState({});
  const [selectedData, setSelectedData] = useState([]);
  const [entryImageLoading, setEntryImageLoading] = useState(true);

  //-------| "Motion" Dropdown Data |-------//
  const motionData = [
    {
      id: 1,
      value: "Towards",
      label: "Towards",
    },

    {
      id: 2,
      value: "Away",
      label: "Away",
    },
  ];

  //-------| Car Reversal Get Api |-------//
  const fetchCarReversalAPi = () => {
    setLoading(true);
    setEntryImageLoading(true);

    axiosConfig
      .get(`GetCarReversalForUI?CarReversalInput={userId:${userId}}`)

      .then((res) => {
        const { statusCode, data } = res.data;

        if (statusCode === 200) {
          setCarReversal(data);
          setCheckedState({});
          setSelectedData([]);
          setDisableBtn(true);
        }

        setLoading(false);
      })

      .catch((err) => {
        console.error("Error in Get Ca rReversal For UI Api", err);
        setLoading(false);
      });
  };

  //-------| Processing & Processed count Get Api |-------//
  const fetchProcessedData = () => {
    axiosConfig
      .get(
        `CarReversalProcessingProcessedCount?ProcessCountInput={userId:${userId}}`
      )
      .then((res) => {
        const { statusCode, processedCount, processingCount } = res.data;
        if (statusCode === 200) {
          dispatch(
            updateDataProcessed({
              processed: processedCount,
              processing: processingCount,
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //-------| PREV btn Get Api |-------//
  const fetchPrevData = () => {
    setLoading(true);
    setEntryImageLoading(true);
    const firstId = carReversal[0].id;

    axiosConfig
      .get(
        `GetCarReversalPreviousRecordForUI?CarReversalInput={userid:${userId},id:${firstId}}`
      )
      .then((res) => {
        const { statusCode, data } = res.data;
        if (statusCode === 200) {
          setCarReversal(data);
          setCheckedState({});
          setSelectedData([]);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(true);
      });
  };

  //-------| Update Btn Get Api |-------//
  const fetchUpdateBtnData = () => {
    const queryParams = {
      CarReversalInput: JSON.stringify(selectedData),
    };

    axiosConfig
      .get(`UpdateCarReversal`, { params: queryParams })
      .then((res) => {
        const { statusCode } = res.data;
        if (statusCode === 200) {
          setCheckedState({});
          setSelectedData([]);
          setDisableBtn(true);
          fetchCarReversalAPi();
          fetchProcessedData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //-------| Reject All Btn Get Api |-------//
  const fetchRejectAllBtnData = () => {
    const { plate, site, date } = carReversal[0];

    const parsedDate = new Date(date);
    const year = parsedDate.getFullYear();
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
    const day = String(parsedDate.getDate()).padStart(2, "0");
    const formatedDate = `${year}-${month}-${day}`;

    const queryParams = {
      RejectAllCarReversalInputDto: JSON.stringify({
        plate: plate,
        site: site,
        date: formatedDate,
        userId: userId,
      }),
    };

    axiosConfig
      .get(`RejectAllCarReversalForUI`, { params: queryParams })
      .then((res) => {
        const { statusCode } = res.data;
        if (statusCode === 200) {
          setCheckedState({});
          setSelectedData([]);
          fetchCarReversalAPi();
          fetchProcessedData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (userId) {
      fetchProcessedData();
      fetchCarReversalAPi();
    }
  }, [userId]);

  //-------| Motion Dropdown Functionality |-------//
  const handleMotionChange = (value, id) => {
    setCarReversal((prevState) =>
      prevState.map((item) =>
        item.id === id ? { ...item, cameraIdentifiedMotion: value } : item
      )
    );

    setSelectedData((prev) =>
      prev.map((item) => (item.id === id ? { ...item, motion: value } : item))
    );
  };

  //-------| Checkbox Functionality |-------//
  const handleCheckbox = (checked, item) => {
    const updatedCheckedState = {
      ...checkedState,
      [item.id]: checked,
    };

    setCheckedState(updatedCheckedState);

    setSelectedData((prev) => {
      if (checked) {
        return [
          ...prev,
          {
            id: item.id,
            userId: userId,
            motion: item.cameraIdentifiedMotion,
          },
        ];
      } else {
        return prev.filter((data) => data.id !== item.id);
      }
    });

    const isAnyChecked = Object.values(updatedCheckedState).some(
      (value) => value
    );
    setDisableBtn(!isAnyChecked);
  };

  //-------| Reject All Btn Functionality |-------//
  const handleRejectAllBtn = () => {
    fetchRejectAllBtnData();
  };

  //-------| Update Btn Functionality |-------//
  const handleUpdateBtn = () => {
    fetchUpdateBtnData();
  };

  return (
    <Container>
      <TopSection>
        <NavigationButton
          disabled={loading || !disableBtn}
          onClick={fetchPrevData}
        >
          <img src={left_arrow} alt="Previous" /> Prev
        </NavigationButton>

        <Title>Potential Car Reversal</Title>

        <UpdateAndNextBtnBox>
          <RejectAllBtn onClick={handleRejectAllBtn}>Reject All</RejectAllBtn>

          <UpdateButton disabled={disableBtn} onClick={handleUpdateBtn}>
            Update
          </UpdateButton>

          <NavigationButton
            disabled={loading || !disableBtn}
            onClick={fetchCarReversalAPi}
          >
            Next <img src={right_arrow} alt="Next" />
          </NavigationButton>
        </UpdateAndNextBtnBox>
      </TopSection>

      {loading ? (
        <Loader>
          <DataFetchIngLoader />
        </Loader>
      ) : (
        <Wrapper>
          <Cover>
            {carReversal.length === 0 ? (
              <Loader>
                <NoData />
              </Loader>
            ) : (
              carReversal.map((item, index) => {
                return (
                  <div key={item.id}>
                    <BoxContainer>
                      <BoxOne>
                        <div>
                          <Tooltip
                            placement="right"
                            overlayInnerStyle={{ width: "820px" }}
                            title={
                              <img
                                src={item?.fullImage}
                                alt="car"
                                style={{ maxWidth: "800px", width: "800px" }}
                              />
                            }
                          >
                            <CarImage href={item.fullImage} target="_blank">
                              <img
                                src={
                                  entryImageLoading
                                    ? Placeholder_image
                                    : item.fullImage
                                }
                                alt="Entry"
                                onLoad={() => setEntryImageLoading(false)}
                              />
                            </CarImage>
                          </Tooltip>
                        </div>

                        <PlateDetailsBox>
                          <Tooltip
                            overlayInnerStyle={{ width: "420px" }}
                            placement="right"
                            title={
                              <img
                                src={item.croppedImage}
                                alt="Entry Detail"
                                style={{
                                  maxWidth: "400px",
                                  width: "400px",
                                  height: "120px",
                                }}
                              />
                            }
                          >
                            <NumberPlate
                              href={item.croppedImage}
                              target="_blank"
                            >
                              <img src={item.croppedImage} alt="Number Plate" />
                            </NumberPlate>
                          </Tooltip>

                          <Field>
                            <PlateName>Plate No:</PlateName>
                            <PlateNo>{item.plate}</PlateNo>
                          </Field>
                        </PlateDetailsBox>
                      </BoxOne>

                      <BoxTwo>
                        <Field>
                          <FieldName>Site</FieldName>
                          <FieldData>{item.site}</FieldData>
                        </Field>

                        <Field>
                          <FieldName>Camera Name:</FieldName>
                          <FieldData>{item.cameraName}</FieldData>
                        </Field>

                        <Field>
                          <FieldName>Camera Direction In:</FieldName>
                          <FieldData>{item.cameraDirectionIN}</FieldData>
                        </Field>

                        <Field>
                          <FieldName>Camera Direction Out:</FieldName>
                          <FieldData>{item.cameraDirectionOUT}</FieldData>
                        </Field>

                        <Field>
                          <FieldName>Direction:</FieldName>
                          <FieldData>{item.direction}</FieldData>
                        </Field>
                      </BoxTwo>

                      <BoxTwo>
                        <Field>
                          <FieldName>Date</FieldName>
                          <FieldData>{formatDatev2(item.date)}</FieldData>
                        </Field>

                        <Field>
                          <FieldName>Time:</FieldName>
                          <FieldData>{formatTime(item.date)}</FieldData>
                        </Field>

                        <Field>
                          <FieldName>Camera Identified Motion:</FieldName>

                          <FieldData>
                            <Select
                              placeholder="Motion"
                              size="small"
                              style={{ width: 120 }}
                              value={item.cameraIdentifiedMotion}
                              onChange={(value) =>
                                handleMotionChange(value, item.id)
                              }
                            >
                              {motionData.map((item) => (
                                <Option key={item.id} value={item.value}>
                                  {item.label}
                                </Option>
                              ))}
                            </Select>
                          </FieldData>
                        </Field>

                        {item.cameraIdentifiedMotion === "static" && (
                          <ValidationText>
                            *Camera identified motion "Static" is invalid and
                            must be corrected.
                          </ValidationText>
                        )}
                      </BoxTwo>

                      <BoxThree>
                        <Checkbox
                          checked={checkedState[item.id] || false}
                          disabled={item.cameraIdentifiedMotion === "static"}
                          onChange={(e) =>
                            handleCheckbox(e.target.checked, item)
                          }
                        >
                          Select
                        </Checkbox>
                      </BoxThree>
                    </BoxContainer>

                    {index !== carReversal.length - 1 && <Divider />}
                  </div>
                );
              })
            )}
          </Cover>
        </Wrapper>
      )}
    </Container>
  );
}

export default CarReversalMain;

const Container = styled.div`
  width: 100%;
  padding: 20px;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const NavigationButton = styled.button`
  width: 80px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #828282;
  border-radius: 5px;
  border: 1px solid #828282;
  background-color: #ffffff;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }

  &:disabled {
    color: #666666;
    background-color: #cccccc;
    cursor: not-allowed;
    border-color: #cccccc;
  }
`;

const Title = styled.h2`
  margin-left: 16rem;
  font-size: 18px;
  font-weight: 600;
  color: ${COLORS.THEME_COLOR};
  text-align: center;
`;

const UpdateAndNextBtnBox = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`;

const RejectAllBtn = styled.button`
  width: 110px;
  height: 25px;
  font-size: 12px;
  font-weight: 500;
  color: #3d619b;
  border: 1px solid #3d619b;
  background-color: #ffffff;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: #fff;
    color: #3d619b;
  }
`;

const UpdateButton = styled.button`
  width: 110px;
  height: 25px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  border: 1px solid #3d619b;
  background-color: #3d619b;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #fff;
    color: #3d619b;
  }

  &:disabled {
    color: #666666;
    background-color: #cccccc;
    cursor: not-allowed;
    border-color: #cccccc;
  }
`;

const Wrapper = styled.div`
  height: calc(100vh - 11rem);
  padding-right: 4px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 12px;
    display: block !important;
    background-color: #cdcdcd;
    border-radius: 50px;
  }

  &::-webkit-scrollbar-track {
    background-color: #ececec;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cdcdcd;
    border-radius: 50px;
  }
`;

const Cover = styled.div`
  padding: 14px;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #d0d1d2;
`;

const BoxContainer = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
  overflow: auto;

  /*-------| Extra small screens (xs) |-------*/
  @media (max-width: 600px) {
    flex: 1;
    display: block;
    flex-direction: column;
  }

  /*-------| Small screens (sm) |-------*/
  @media all and (min-width: 599px) and (max-width: 900px) {
    flex: 1;
    display: block;
    flex-direction: column;
  }

  /*-------| Medium screens (md) |-------*/
  @media all and (min-width: 899px) and (max-width: 1200px) {
    flex: 1;
    display: block;
    flex-direction: column;
  }
`;

const BoxOne = styled.div`
  min-height: 8rem;
  height: auto;
  flex: 0.4;
  display: flex;
  gap: 20px;
  padding-right: 20px;
  border-right: 1px solid #d0d1d2;

  /*-------| Extra small screens (xs) |-------*/
  @media (max-width: 600px) {
    flex: 1;
    border: none;
  }

  /*-------| Small screens (sm) |-------*/
  @media all and (min-width: 599px) and (max-width: 900px) {
    flex: 1;
    border: none;
  }

  /*-------| Medium screens (md) |-------*/
  @media all and (min-width: 899px) and (max-width: 1200px) {
    flex: 1;
    border: none;
  }
`;

const PlateDetailsBox = styled.div`
  flex: 4;
`;

const PlateName = styled.span`
  font-size: 13px;
  font-weight: 500;
  color: #6a6a6a;
`;

const BoxTwo = styled.div`
  min-height: 8rem;
  height: auto;
  flex: 0.4;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-right: 20px;
  border-right: 1px solid #d0d1d2;

  /*-------| Extra small screens (xs) |-------*/
  @media (max-width: 600px) {
    flex: 1;
    border: none;
  }

  /*-------| Small screens (sm) |-------*/
  @media all and (min-width: 599px) and (max-width: 900px) {
    flex: 1;
    border: none;
  }

  /*-------| Medium screens (md) |-------*/
  @media all and (min-width: 899px) and (max-width: 1200px) {
    flex: 1;
    border: none;
  }
`;

const BoxThree = styled.div`
  min-height: 8rem;
  height: auto;
  flex: 0.1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CarImage = styled.a`
  display: block;
  flex: 1;
  min-width: 150px;
  height: 120px;
  border-radius: 8px;
  overflow: hidden;

  @media all and (max-width: 1550px) {
    max-width: 150px;
  }

  img {
    display: block;
    width: 200px;
    height: 120px;
    object-fit: cover;
  }
`;

const NumberPlate = styled.a`
  height: 40px;
  width: 150px;
  display: block;
  margin-bottom: 5px;

  img {
    display: block;
    height: 100%;
    width: 100%;
  }
`;

const Field = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const FieldName = styled.span`
  flex: 0.4;
  font-size: 13px;
  font-weight: 500;
  color: #6a6a6a;
`;

const FieldData = styled.span`
  flex: 0.6;
  font-size: 12px;
  font-weight: 400;
  color: #060606;
  text-transform: capitalize;
`;

const PlateNo = styled.span`
  flex: 0.6;
  font-size: 18px;
  font-weight: 700;
  color: #060606;
  font-family: "Roboto Mono";
  text-transform: capitalize;
`;

const ValidationText = styled.span`
  margin-top: 10px;
  font-size: 10px;
  font-weight: 400;
  color: #df4a4a;
`;

const Loader = styled.div`
  min-height: 290px;
  display: flex;
  justify-content: center;
  align-content: center;
`;
